<template>
  <v-card flat>
    <v-card-text class="d-flex">
      <v-spacer />
      <v-card max-width="600" flat>
        <v-card-title>
          <div class="text-center flex-grow-1">
            <img alt="logo" src="@/assets/logo_sm.png"><br />
            <h2>Welcome to Pondr</h2>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="text-center">
            <div class="mb-1">
              <v-chip color="orange" dark class="mr-2">Tasks <v-icon class="ml-2">mdi-check</v-icon></v-chip>
            </div>
            <div class="mb-1">
              <v-chip color="blue" dark class="mr-2">Events <v-icon class="ml-2">mdi-calendar</v-icon></v-chip>
            </div>
            <div class="mb-1">
              <v-chip color="green" dark class="mr-2">Contacts <v-icon class="ml-2">mdi-account-group</v-icon></v-chip>
            </div>
            <div class="mb-5">
              <v-chip color="purple" dark class="mr-2">Files <v-icon class="ml-2">mdi-file</v-icon></v-chip>
            </div>
          </div>
          All managed in the same place which we argue is all you really need to live a well
          organized life.  Pondr integrates with Google services like Google Calendar, Google Contacts, and Google Drive to help keep all of your
          information consolidated and available but adds the ability to inter-relate items to make it easier to plan each day.
          Pondr will never use your information for anything but helping you to live life intentionally.
          If you're interested in integrating your Google information with Pondr
          <v-btn class="mt-5" block max-width="200" color="primary" @click="start">Continue to Pondr</v-btn>
          <div class="d-flex justify-space-around">
            <v-btn to="/privacy" text color="grey"  dark>Privacy Policy</v-btn>
            <v-btn to="/terms" text color="grey" dark>Terms of Use</v-btn>
          </div>
        </v-card-text>
      </v-card>
      <v-spacer />
    </v-card-text>
  </v-card>
</template>

<script>
import authenticator from '@/firebase/authenticator'

export default {
  name: 'Home',
  created () {
    authenticator.getCurrentUser().then(() => {
      this.$router.push('Today')
    })
  },
  methods: {
    start () {
      this.$router.push('Login')
    }
  }
}
</script>

<style scoped>

</style>
