import firebase from 'firebase/app'
import 'firebase/firestore'
const initializedFirebase = firebase.initializeApp({
  apiKey: 'AIzaSyA688zDG_6UgEy8m06rHK2Tve0N8QQj1tw',
  authDomain: 'pondr-e9688.firebaseapp.com',
  projectId: 'pondr-e9688',
  storageBucket: 'pondr-e9688.appspot.com',
  messagingSenderId: '380017049597',
  appId: '1:380017049597:web:0418dc281118c0e3256394',
  measurementId: 'G-EQCK5P4WSH'
})

export { initializedFirebase }
