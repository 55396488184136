<template>
  <v-dialog
    v-model="showing"
    fullscreen
    hide-overlay
    transition="dialog-top-transition">
    <v-card>
      <v-toolbar elevation="2" color="purple" dark>
        <v-toolbar-title>File</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn dark text @click="saveFile()">Save</v-btn>
          <v-btn dark text @click="cancel()">Cancel</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-form>
          <v-row class="mt-5">
            <v-col cols="12">
              <v-text-field autofocus dense label="Title" v-model="title"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-n5">
            <v-col cols="12">
              <v-btn-toggle class="d-flex flex-row" dense v-model="type" mandatory tile color="primary">
                <v-btn class="flex-grow-1" value="text">
                  <v-icon color="grey" class="mr-3">mdi-note-text</v-icon>
                  Note
                </v-btn>
                <v-btn class="flex-grow-1" value="list">
                  <v-icon color="grey" class="mr-3">mdi-view-list</v-icon>
                  List
                </v-btn>
                <v-btn class="flex-grow-1" value="upload">
                  <v-icon color="grey" class="mr-3">mdi-file-image</v-icon>
                  Upload
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row class="mx-n3 mt-n4">
            <v-col cols="12">
              <tag-selector class="my-2" v-model="tags"></tag-selector>
              <v-slide-x-transition mode="out-in">
                <v-textarea class="mt-n4" dense rows="20" v-if="type === 'text'" label="Note Text" v-model="text"></v-textarea>
                <v-list class="my-n5" dense v-if="type === 'list'">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field label="Label" persistent-hint hint="Optional" dense v-model="newKey" />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea append-outer-icon="mdi-check" @click:append-outer="addListItem" rows="2" label="Text" ref="newText" dense v-model="newText" />
                    </v-col>
                  </v-row>
                  <v-list-item class="mx-n4" dense v-for="(listItem, index) in listItems" :key="index">
                    <v-list-item-content>
                      <span v-if="listItem.key">
                        <b>{{listItem.key}}</b>:
                      </span>
                      <div style="white-space: pre-wrap;">{{listItem.text}}</div>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-menu offset-y dense>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on="on" v-bind="attrs">mdi-dots-vertical</v-icon>
                        </template>
                        <v-list dense dark color="purple lighten-2" nav>
                          <v-list-item @click="editListItem(listItem)">
                            <v-list-item-icon>
                              <v-icon>
                                mdi-pencil-circle-outline
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                Edit
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item @click="removeListItem(listItem)">
                            <v-list-item-icon>
                              <v-icon>
                                mdi-delete-circle-outline
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                Remove
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-file-input class="mt-n4" dense v-if="type === 'upload'" label="file" v-model="uploadFile"></v-file-input>
              </v-slide-x-transition>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import fileApi from '@/firebase/fileApi'
import TagSelector from '@/components/inputs/TagSelector'
import tagCoordinator from '@/components/services/TagCoordinator'

export default {
  name: 'FileEditor',
  components: { TagSelector },
  props: {
    trigger: Boolean,
    file: Object
  },
  data: () => ({
    title: null,
    id: null,
    type: 'text',
    uploadFile: null,
    text: null,
    listItems: [],
    showing: false,
    newKey: null,
    newText: null,
    editingListItem: null,
    tags: [],
    selectedTags: tagCoordinator.selectedTags
  }),
  watch: {
    file (file) {
      if (file) {
        this.id = file.id
        this.title = file.title
        this.type = file.type
        this.uploadFile = file.uploadFile
        this.text = file.text
        this.tags = file.id ? file.tags || [] : this.selectedTags.map(t => t.text)
        this.listItems = file.listItems || []
      }
    },
    trigger (val) {
      this.showing = val
    }
  },
  methods: {
    setFileInfo (event) {
      debugger
      this.uploadFile = event.target.files[0]
    },
    async saveFile () {
      if (this.newText) {
        this.addListItem()
      }
      const file = {
        title: this.title,
        id: this.id,
        type: this.type,
        tags: this.tags,
        text: this.text,
        uploadFile: this.uploadFile,
        listItems: this.listItems
      }
      if (!file.id) delete file.id
      if (!file.text) delete file.text
      if (!file.uploadFile) delete file.uploadFile
      await fileApi.saveFile(file)
      this.cancel()
    },
    cancel () {
      this.$emit('update:trigger', false)
      this.title = null
      this.text = null
      this.listItems = []
      this.tags = []
      this.uploadFile = null
      this.fileUploadInfo = null
      this.type = 'text'
    },
    addListItem () {
      if (this.editingListItem) {
        this.editingListItem.key = this.newKey
        this.editingListItem.text = this.newText
        this.editingListItem = null
      } else {
        if (this.newText || this.newKey) {
          this.listItems.push({
            key: this.newKey,
            text: this.newText
          })
        }
      }
      this.newKey = null
      this.newText = null
    },
    editListItem (item) {
      this.newText = item.text
      this.newKey = item.key
      this.editingListItem = item
      this.$refs.newText.focus()
    },
    removeListItem (item) {
      this.listItems.splice(this.listItems.indexOf(item), 1)
    }
  }
}
</script>

<style scoped>

</style>
