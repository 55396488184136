 <template>
  <v-dialog v-model="pickingDate">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="my-4">
        <div class="mb-2">
          <v-icon>mdi-calendar</v-icon>
          {{formattedTaskDate}}
          <v-icon v-show="value" @click.stop="clearDate" class="ml-5">mdi-close</v-icon>
        </div>
        <div>
          <v-btn-toggle dark v-model="btnGroupValue">
            <v-btn color="primary" small @click.stop="setToday">Today</v-btn>
            <v-btn color="primary" small @click.stop="setTomorrow">Tomorrow</v-btn>
          </v-btn-toggle>
        </div>
      </div>
    </template>
    <v-date-picker @change="emitValueAndClose($event)" :value="pickedDate"></v-date-picker>
  </v-dialog>
</template>

<script>
import dateTimeUtils from '@/components/services/dateTimeUtils'

export default {
  name: 'PDatePicker',
  props: ['value'],
  data: () => ({
    pickedDate: null,
    pickingDate: false,
    btnGroupValue: null
  }),
  computed: {
    formattedTaskDate () {
      if (!this.value) {
        return 'Not Scheduled'
      } else {
        return dateTimeUtils.toDateDisplay(this.value)
      }
    }
  },
  watch: {
    value: {
      handler (newVal) {
        this.pickedDate = newVal ? dateTimeUtils.toDatePickerFormat(newVal) : null
      },
      immediate: true
    },
    btnGroupValue () {
      this.btnGroupValue = null
    }
  },
  methods: {
    clearDate () {
      this.pickedDate = null
      this.$emit('input', null)
    },
    emitValueAndClose (value) {
      const dateObjFromPickerValue = dateTimeUtils.toDateObjectFromDatePicker(value)
      this.$emit('input', dateObjFromPickerValue)
      this.pickingDate = false
    },
    setToday () {
      this.$emit('input', dateTimeUtils.getTimelessToday())
    },
    setTomorrow () {
      const tomorrow = dateTimeUtils.getTimelessToday()
      tomorrow.setDate(tomorrow.getDate() + 1)
      this.$emit('input', tomorrow)
    }
  }
}
</script>

<style scoped>

</style>
