const toDatePickerFormat = (date) => {
  date = date.toDate ? date.toDate() : new Date(date)
  if (date.getTime) {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
  } else {
    return null
  }
}
const toTimePickerFormat = (dateTime) => {
  dateTime = dateTime.toDate ? dateTime.toDate() : new Date(dateTime)
  if (dateTime.getTime) {
    return `${dateTime.getHours().toString().padStart(2, '0')}:${dateTime.getMinutes().toString().padStart(2, '0')}`
  } else {
    return null
  }
}

const toTimeDisplay = (dateTime) => {
  const providedDate = dateTime.toDate ? dateTime.toDate() : new Date(dateTime)
  if (providedDate.getHours) {
    const hours = providedDate.getHours()
    const minutes = providedDate.getMinutes()
    if (hours === 0 && minutes === 0) {
      return '' // don't show time if it's midnight
    } else {
      return `${hours > 12 ? hours - 12 : hours}:${minutes.toString().padStart(2, '0')} ${hours >= 12 ? 'PM' : 'AM'}`
    }
  } else {
    throw new Error(`Expected date object but got ${typeof dateTime}`)
  }
}
const toDateTimeDisplay = (dateTime) => {
  return `${toDateDisplay(dateTime)} @ ${toTimeDisplay(dateTime)}`
}
const dayNameArray = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const toDateDisplay = (dateTime, format) => {
  const diffInDays = daysUntil(dateTime)
  const beginningOfToday = getTimelessToday()
  const providedDate = dateTime.toDate ? dateTime.toDate() : new Date(dateTime)
  let dayIndex = 0
  switch (diffInDays) {
    case 0:
      return 'Today'
    case -1:
      return 'Yesterday'
    case 1:
      return 'Tomorrow'
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return dayNameArray[(beginningOfToday.getDay() + diffInDays) % 7]
    case -2:
    case -3:
    case -4:
    case -5:
    case -6:
      dayIndex = beginningOfToday.getDay() + diffInDays
      return `Last ${dayNameArray[dayIndex < 0 ? dayIndex + 7 : dayIndex]}`
    default:
      return providedDate.toLocaleDateString('en-US', { weekday: (format === 'short' ? 'short' : 'long'), month: 'long', day: 'numeric' })
  }
}
const toDurationDisplay = (eventInfo) => {
  if (eventInfo.start) {
    const startDate = new Date(eventInfo.start)
    const endDate = new Date(eventInfo.end)
    const startDateString = toDateDisplay(startDate)
    if (eventInfo.multiDay) {
      const endDateString = toDateDisplay(endDate)
      return `${startDateString} - ${endDateString}`
    } else {
      const startTimeString = `${toTimeDisplay(startDate)}`
      const endTimeString = `${toTimeDisplay(endDate)}`
      const timeDisplay = startTimeString ? `@ ${startTimeString} - ${endTimeString}` : ''
      return `${startDateString} ${timeDisplay}`
    }
  } else {
    return 'No Start Time'
  }
}
const toEffortDisplay = (effortAsNumber) => {
  if (!effortAsNumber) {
    return '-:--'
  }
  const hours = Math.floor(Number(effortAsNumber) / 60)
  const minutes = Number(effortAsNumber) % 60
  return `${hours}:${minutes.toString().padStart(2, '0')}`
}
const getTimelessToday = () => {
  const now = new Date()
  return new Date(now.setHours(0, 0, 0, 0))
}
const toTaskScheduleDisplay = (task) => {
  const taskDate = task.date
  const taskTimeOfDay = task.timeOfDay
  if (!taskDate || (!taskDate.toDate && !taskDate.getTime)) {
    return 'Not Scheduled'
  } else {
    const taskDateAsDate = taskDate.toDate ? taskDate.toDate() : taskDate
    const dateDisplay = toDateDisplay(taskDateAsDate)
    let timeOfDayDisplay = dateDisplay === 'Today' ? 'at Any Time' : 'Any Time'
    switch (taskTimeOfDay) {
      case 'morning':
        timeOfDayDisplay = dateDisplay === 'Today' ? 'in the Morning' : 'Morning'
        break
      case 'midday':
        timeOfDayDisplay = dateDisplay === 'Today' ? 'during Mid-Day' : 'Mid-Day'
        break
      case 'evening':
        timeOfDayDisplay = dateDisplay === 'Today' ? 'in the Evening' : 'Evening'
        break
    }
    return `${dateDisplay} ${timeOfDayDisplay}`
  }
}
const daysUntil = (dateTime) => {
  if (typeof dateTime === 'string') {
    dateTime = new Date(dateTime)
  } else if (dateTime.toDate) {
    dateTime = dateTime.toDate()
  } else {
    dateTime = new Date(dateTime)
  }
  if (dateTime.getTime) {
    const beginningOfToday = getTimelessToday()
    const dayOfMilliseconds = 86400000
    const providedDate = dateTime.toDate ? dateTime.toDate() : new Date(dateTime)
    const dateTimeInMilliseconds = providedDate.getTime()
    return Math.floor((dateTimeInMilliseconds - beginningOfToday) / dayOfMilliseconds)
  } else {
    throw new Error('Could not get daysUntil from value ' + (dateTime || '').toString())
  }
}
const toDateObjectFromDatePicker = (datePickerString) => {
  if (datePickerString) {
    const splitDate = datePickerString.split('-')
    return new Date(splitDate[0], splitDate[1] - 1, splitDate[2])
  } else {
    return null
  }
}
const ensureDate = (potentialDate, defaultToToday) => {
  if (!potentialDate) return defaultToToday ? getTimelessToday() : null
  else {
    if (potentialDate.toDate) return potentialDate.toDate()
    if (potentialDate.getTime) return potentialDate
    if (typeof potentialDate === 'string') return new Date(potentialDate)
    else throw new Error(`dateTimeUtilities:ensureDate received something that couldn't be converted to a date as ${typeof potentialDate}`)
  }
}

export default {
  toDatePickerFormat,
  toTimePickerFormat,
  toTimeDisplay,
  toDurationDisplay,
  toEffortDisplay,
  getTimelessToday,
  toDateTimeDisplay,
  toDateDisplay,
  toTaskScheduleDisplay,
  daysUntil,
  toDateObjectFromDatePicker,
  ensureDate
}
