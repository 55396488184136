var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[_c('v-combobox',{attrs:{"filter":_vm.filter,"hide-no-data":!_vm.search,"items":_vm.items,"search-input":_vm.search,"hide-selected":"","label":"Add Tag","multiple":"","small-chips":"","flat":"","solo":"","dense":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',{on:{"click":function($event){return _vm.addTag()}}},[_c('span',{staticClass:"subheading"},[_vm._v("Tag with")]),_c('v-chip',{staticClass:"ml-2",attrs:{"color":(_vm.nextColor + " lighten-3"),"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.search)+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"color":((item.color) + " lighten-3"),"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" $delete ")])],1):_vm._e()]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(_vm.editing === item)?_c('div',{staticClass:"d-flex align-center"},[_c('v-text-field',{attrs:{"autofocus":"","flat":"","background-color":"transparent","hide-details":"","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.edit(index, item)}},model:{value:(_vm.editing.text),callback:function ($$v) {_vm.$set(_vm.editing, "text", $$v)},expression:"editing.text"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"420"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"size":"20","rounded":"","color":((_vm.editing.color) + " lighten-2")}},'v-avatar',attrs,false),on))]}}],null,true),model:{value:(_vm.pickingColor),callback:function ($$v) {_vm.pickingColor=$$v},expression:"pickingColor"}},[_c('v-card',[_c('v-card-text',[_c('v-color-picker',{attrs:{"dot-size":"25","hide-canvas":"","hide-inputs":"","hide-sliders":"","mode":"rgba","show-swatches":"","swatches-max-height":"400"},model:{value:(_vm.editing.color),callback:function ($$v) {_vm.$set(_vm.editing, "color", $$v)},expression:"editing.color"}})],1),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){_vm.pickingColor = false}}},[_vm._v("Close")])],1)],1)],1)],1):_c('v-chip',{attrs:{"color":((item.color) + " lighten-3"),"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-spacer'),_c('v-list-item-action',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.edit(index, item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.editing !== item ? 'mdi-pencil' : 'mdi-check'))])],1)],1)]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }