<template>
  <v-navigation-drawer
    v-model="drawerOpen"
    absolute
    temporary
  >
    <v-list nav dense>
      <v-list-item-group>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Me</v-list-item-title>
        </v-list-item>
        <v-divider dark></v-divider>
        <v-list-item @click="archiveTasks">
          <v-list-item-icon>
            <v-icon>mdi-checkbox-marked</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Archive Completed</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-divider></v-divider>
    <v-chip v-for="tag in selectedTags"
            @click="toggleTag(tag)"
            :key="tag.id"
            :color="`${tag.color} lighten-2`"
            dark
            label
            small
            class="ma-4">
      {{tag.text}}
    </v-chip>
    <v-container v-if="selectedTags.length === 0" class="grey--text caption">Select a tag to filter items</v-container>
    <v-divider></v-divider>
    <v-btn-toggle tile class="d-flex" v-if="selectedTags.length > 0">
      <v-btn small class="flex-grow-1">Any</v-btn>
      <v-btn small class="flex-grow-1">All</v-btn>
    </v-btn-toggle>
    <v-divider v-if="selectedTags.length > 0"></v-divider>
    <v-chip v-for="tag in unselectedTags"
            @click="toggleTag(tag)"
            :key="tag.id"
            color="grey lighten-1"
            dark
            label
            small
            class="ma-4">
      {{tag.text}}
    </v-chip>
  </v-navigation-drawer>
</template>

<script>
import taskApi from '@/firebase/taskApi'
import authenticator from '@/firebase/authenticator'
import { db } from '@/firebase/firestore'
import tagService from '@/components/services/TagCoordinator'

export default {
  name: 'SideDrawer',
  props: {
    drawerActive: Boolean
  },
  created () {
    authenticator.getCurrentUser().then((user) => {
      this.$bind('tags', db.collection(`users/${user.id}/tags`).orderBy('order'))
      this.user = user
    })
  },
  data: () => ({
    drawerOpen: false,
    tags: [],
    selectedTags: tagService.selectedTags
  }),
  computed: {
    unselectedTags () {
      return this.tags.filter(t => !this.selectedTags.includes(t))
    }
  },
  methods: {
    toggleTag (tag) {
      if (tag.selected) {
        tag.selected = false
        tagService.deselectTag(tag)
      } else {
        tag.selected = true
        tagService.selectTag(tag)
      }
    },
    async archiveTasks () {
      const archivedTaskCount = await taskApi.archiveTasks()
      this.$emit('message', { color: 'success', message: `${archivedTaskCount} Tasks Archived` })
      this.drawerOpen = false
    }
  },
  watch: {
    drawerActive (val) {
      if (val) {
        this.drawerOpen = val
      }
    },
    drawerOpen (val) {
      if (!val) {
        this.$emit('drawerClosed')
      }
    },
    selectedTags (newTags) {
      this.tags.forEach(t => {
        t.selected = newTags.includes(t)
      })
    }
  }
}
</script>

<style scoped>

</style>
