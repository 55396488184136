import { db } from '@/firebase/firestore'
import authenticator from '@/firebase/authenticator'

const getTaskPath = async () => {
  const currentUser = await authenticator.getCurrentUser()
  return `users/${currentUser.id}/tasks`
}
const saveTask = async (task, overwrite) => {
  const tasksPath = await getTaskPath()
  if (task.id) {
    if (overwrite) {
      await db.doc(`${tasksPath}/${task.id}`).set(task)
    } else {
      await db.doc(`${tasksPath}/${task.id}`).update(task)
    }
  } else {
    await db.collection(tasksPath).add(task)
  }
}
const archiveTasks = async () => {
  const tasksRef = db.collection(await getTaskPath())
  const query = tasksRef
    .where('complete', '==', true)
    .where('daily', '!=', true)
  const completedTaskSnapshots = await query.get()
  let count = 0
  const deletePromises = []
  completedTaskSnapshots.forEach((task) => {
    count++
    deletePromises.push(tasksRef.doc(task.id.toString()).delete())
  })
  await Promise.all(deletePromises)
  return count
}

const deleteTask = async (taskId) => {
  const tasksRef = db.collection(await getTaskPath())
  await tasksRef.doc(taskId.toString()).delete()
}
const clearProperty = async (taskId, propertyName) => {
  const tasksRef = db.collection(await getTaskPath())
  await tasksRef.doc(`${taskId}/${propertyName}`).delete()
}

export default {
  saveTask,
  archiveTasks,
  deleteTask,
  clearProperty
}
