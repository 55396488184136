import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home'

Vue.use(VueRouter)

const index = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/components/Pages/Login')
  },
  {
    path: '/Today',
    name: 'Today',
    component: () => import(/* webpackChunkName: "today" */ '@/components/Pages/Today')
  },
  {
    path: '/Tasks',
    name: 'Tasks',
    component: () => import(/* webpackChunkName: "tasks" */ '@/components/Pages/Tasks')
  },
  {
    path: '/Events',
    name: 'Events',
    component: () => import(/* webpackChunkName: "events" */ '@/components/Pages/Events')
  },
  {
    path: '/Contacts',
    name: 'Contacts',
    component: () => import(/* webpackChunkName: "contacts" */ '@/components/Pages/Contacts')
  },
  {
    path: '/Files',
    name: 'Files',
    component: () => import(/* webpackChunkName: "files" */ '@/components/Pages/Files')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '@/components/Pages/Privacy')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "privacy" */ '@/components/Pages/Terms')
  }
]

const router = new VueRouter({
  routes: index
})

export default router
