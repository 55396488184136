import { db } from '@/firebase/firestore'
import authenticator from '@/firebase/authenticator'
import firebase from 'firebase'

const getFilePath = async () => {
  const currentUser = await authenticator.getCurrentUser()
  return `users/${currentUser.id}/files`
}

const uploadFile = async (file) => {
  const currentUser = await authenticator.getCurrentUser()
  const fileRef = firebase.storage().ref(`${currentUser.id}/${file.name}`)
  await fileRef.put(file)
  return await fileRef.getDownloadURL()
}

const getFileDataUrl = (file) => new Promise(resolve => {
  // someday, I'll know how to get this since await fileRef.getData() doesn't work
  // if (file && file.uploadInfo && file.uploadInfo.name) {
  //   authenticator.getCurrentUser().then(async currentUser => {
  //     const fileRef = firebase.storage().ref(`${currentUser.uid}/${file.uploadInfo.name}`)
  //     const fileData = fileRef.toString()
  //     const reader = new FileReader()
  //     reader.onloadend = () => {
  //       resolve(reader.result.split(';')[1])
  //     }
  //     reader.readAsDataURL(fileData)
  //   })
  // } else {
  //   resolve(null)
  // }
})

const saveFile = async (fileInfo) => {
  const filesPath = await getFilePath()
  fileInfo.lastSaved = new Date()
  if (fileInfo.id) {
    await db.collection(filesPath).doc(fileInfo.id).update(fileInfo)
  } else {
    if (fileInfo.type === 'upload') {
      const downloadUrl = await uploadFile(fileInfo.uploadFile)
      fileInfo.uploadInfo = {
        downloadUrl: downloadUrl,
        name: fileInfo.uploadFile.name,
        type: fileInfo.uploadFile.type,
        size: fileInfo.uploadFile.size
      }
      delete fileInfo.uploadFile
    }
    await db.collection(filesPath).add(fileInfo)
  }
}
const deleteFile = async (fileId) => {
  const filesPath = await getFilePath()
  const fileSnapshot = await db.collection(filesPath).doc(fileId).get()
  const file = fileSnapshot.data()
  if (file.uploadInfo && file.uploadInfo.name) {
    const currentUser = await authenticator.getCurrentUser()
    const fileRef = firebase.storage().ref(`${currentUser.id}/${file.uploadInfo.name}`)
    await fileRef.delete()
  }
  await db.collection(filesPath).doc(fileId).delete()
}

export default {
  saveFile,
  deleteFile,
  uploadFile,
  getFileDataUrl
}
