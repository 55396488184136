import { db } from '@/firebase/firestore'
import authenticator from '@/firebase/authenticator'

const saveContact = async (contact) => {
  const user = await authenticator.getCurrentUser()
  const contactsRef = db.collection(`users/${user.id}/contacts`)
  if (contact.id) {
    await contactsRef.doc(contact.id.toString()).update(contact)
  } else {
    await contactsRef.add(contact)
  }
}
const deleteContact = async (contact) => {
  const user = await authenticator.getCurrentUser()
  const contactsRef = db.collection(`users/${user.id}/contacts`)
  if (contact.id) {
    await contactsRef.doc(contact.id.toString()).delete()
  }
}

export default {
  saveContact,
  deleteContact
}
