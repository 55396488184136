<template>
  <v-app id="inspire">
    <side-drawer @message="showMessage"
                 style="position: fixed;"
                 :drawerActive="drawer"
                 v-on:drawerClosed="drawer = false"></side-drawer>
    <v-main app class="mb-10">
      <router-view @showDrawer="drawer = true"
                   @editTask="editTask"
                   @editEvent="editEvent"
                   @editContact="editContact"
                   @editFile="editFile"
                   @addTaskRequested="addTask"
                   @addEventRequested="addEvent"
                   @addContactRequested="addContact"
                   @addFileRequested="addFile"></router-view>
      <task-editor :trigger="editingTask" :task="taskToEdit" @closedTaskEditor="editingTask = false"></task-editor>
      <event-editor :trigger.sync="editingEvent" :event="eventToEdit"></event-editor>
      <contact-editor :trigger.sync="editingContact" :contact="contactToEdit" />
      <file-editor :trigger.sync="editingFile" :file="fileToEdit"></file-editor>
    </v-main>
    <v-snackbar app timeout="3000" :color="color" v-model="showSnack">
      {{message}}
    </v-snackbar>
    <v-bottom-navigation
      v-model="selectedViewIndex"
      :background-color="computedColor"
      dark
      fixed
      app
      grow
      shift>
      <v-btn to="/Today">
        <span>Today</span>
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-btn to="/Tasks">
        <span>Tasks</span>
        <v-icon>mdi-checkbox-marked</v-icon>
      </v-btn>
      <v-btn to="/Events">
        <span>Events</span>
        <v-icon>mdi-calendar</v-icon>
      </v-btn>
      <v-btn to="/Contacts">
        <span>Contacts</span>
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <v-btn to="/Files">
        <span>Files</span>
        <v-icon>mdi-file-multiple-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import SideDrawer from '@/components/navigation/SideDrawer'
import TaskEditor from '@/components/editors/TaskEditor'
import EventEditor from '@/components/editors/EventEditor'
import FileEditor from '@/components/editors/FileEditor'
import ContactEditor from '@/components/editors/ContactEditor'

export default {
  components: {
    ContactEditor,
    FileEditor,
    TaskEditor,
    SideDrawer,
    EventEditor
  },
  data: () => ({
    drawer: null,
    dialed: false,
    editingEvent: false,
    editingTask: false,
    editingContact: false,
    editingFile: false,
    selectedViewIndex: 0,
    taskToEdit: {},
    eventToEdit: {},
    contactToEdit: {},
    fileToEdit: {},
    message: '',
    color: '',
    showSnack: false,
    itemToLink: null
  }),
  methods: {
    addTask () {
      this.taskToEdit = {}
      this.editingTask = true
    },
    addEvent () {
      this.eventToEdit = {}
      this.editingEvent = true
    },
    addContact () {
      this.contactToEdit = {}
      this.editingContact = true
    },
    addFile () {
      this.fileToEdit = {}
      this.editingFile = true
    },
    showMessage (args) {
      this.message = args.message
      this.color = args.color
      this.showSnack = true
    },
    editFile (file) {
      this.fileToEdit = file
      this.editingFile = true
    },
    editEvent (event) {
      this.eventToEdit = event
      this.editingEvent = true
    },
    editContact (contact) {
      this.contactToEdit = contact
      this.editingContact = true
    },
    editTask (task) {
      this.taskToEdit = task
      this.editingTask = true
    }
  },
  computed: {
    computedColor () {
      switch (this.selectedViewIndex) {
        case 0:
          return 'black'
        case 1:
          return 'orange'
        case 2:
          return 'blue'
        case 3:
          return 'green'
        case 4:
          return 'purple'
        default:
          return 'black'
      }
    }
  }
}
</script>
