import firebase from 'firebase/app'
import 'firebase/auth'
import { db } from './firestore'
import router from '@/router'

let user = null

let loginResolver = null
const logIn = () => new Promise((resolve, reject) => {
  const provider = new firebase.auth.GoogleAuthProvider()
  provider.addScope('https://www.googleapis.com/auth/contacts')
  provider.addScope('https://www.googleapis.com/auth/calendar')
  provider.addScope('https://www.googleapis.com/auth/tasks')
  provider.addScope('https://www.googleapis.com/auth/drive')
  firebase.auth().signInWithPopup(provider).then(authResult => {
    db.collection('users').doc(authResult.user.uid).update({
      name: authResult.user.displayName,
      email: authResult.user.email
    }).then(async () => {
      loginResolver(authResult.user)
      user = authResult.user
      await router.push('/Today')
      // gapi.load('client', () => {
      //   gapi.client.init({
      //     apiKey: 'AIzaSyA688zDG_6UgEy8m06rHK2Tve0N8QQj1tw',
      //     clientId: '380017049597-91d3dfqgbu57jiotddml1ifhptm9vv6f.apps.googleusercontent.com'
      //   })
      // })
    })
  })
})
const userResolver = (resolve, reject) => {
  loginResolver = resolve
  firebase.auth().onAuthStateChanged(async authUser => {
    if (!authUser) {
      // load the login page and let it trigger login
      if (router.history.current.name !== 'Home') {
        await router.push('/Login')
      }
    } else {
      await db.collection('users').doc(authUser.uid).update({
        name: authUser.displayName,
        email: authUser.email
      })
      const fbUser = await db.doc(`users/${authUser.uid}`).get()
      const userData = fbUser.data()
      userData.id = authUser.uid
      resolve(userData)
      user = userData
    }
  })
}
const userPromise = new Promise(userResolver)
const getCurrentUser = () => {
  if (user) return Promise.resolve(user)
  else return userPromise
}

getCurrentUser().then((resolvedUser) => {
  user = resolvedUser
})

export default { getCurrentUser, logIn }
