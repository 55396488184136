<template>
  <v-dialog fullscreen v-model="open" hide-overlay transition="dialog-top-transition">
    <v-card>
      <v-toolbar color="green" tile dark>
        <v-toolbar-title>
          Contact
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="saveContact">Save</v-btn>
          <v-btn dark text @click="cancel">Cancel</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-form class="pa-5">
        <v-row>
          <v-col>
            <v-text-field label="First" v-model="firstName" />
          </v-col>
          <v-col>
            <v-text-field label="Last" v-model="lastName" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field label="Role" v-model="role" />
          </v-col>
          <v-col>
            <v-text-field label="Organization" v-model="organization" />
          </v-col>
        </v-row>
        <v-text-field label="E-mail" v-model="email" />
        <v-text-field label="Phone" v-model="phone" />
        <v-text-field label="Address" v-model="address" />
        <tag-selector v-model="tags"></tag-selector>
        <v-textarea label="Notes" v-model="notes" />
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import contactApi from '@/firebase/contactApi'
import firebase from 'firebase'
import TagSelector from '@/components/inputs/TagSelector'
import tagCoordinator from '@/components/services/TagCoordinator'

export default {
  name: 'ContactEditor',
  components: { TagSelector },
  props: {
    trigger: Boolean,
    contact: Object
  },
  data: () => ({
    open: false,
    firstName: '',
    lastName: '',
    organization: '',
    role: '',
    phone: '',
    address: '',
    notes: '',
    email: '',
    tags: [],
    selectedTags: tagCoordinator.selectedTags
  }),
  computed: {

  },
  watch: {
    trigger (val) {
      this.open = val
    },
    open (val) {
      if (!val) this.$emit('update:trigger', false)
    },
    contact (contactToBind) {
      if (contactToBind) {
        this.firstName = contactToBind.firstName || ''
        this.lastName = contactToBind.lastName || ''
        this.phone = contactToBind.phone || ''
        this.address = contactToBind.address || ''
        this.notes = contactToBind.notes || ''
        this.id = contactToBind.id
        this.role = contactToBind.role || ''
        this.organization = contactToBind.organization || ''
        this.email = contactToBind.email || ''
        this.tags = contactToBind.id ? contactToBind.tags || [] : this.selectedTags.map(t => t.text)
      }
    }
  },
  methods: {
    async saveContact () {
      const contact = {
        firstName: this.firstName,
        lastName: this.lastName,
        organization: this.organization,
        role: this.role,
        phone: (this.phone || '').replace(/\D/g, ''),
        address: this.address,
        email: this.email,
        notes: this.notes,
        id: this.id,
        tags: this.tags
      }
      if (!contact.id) delete contact.id
      else {
        if (!contact.phone) contact.phone = firebase.firestore.FieldValue.delete()
        if (!contact.address) contact.address = firebase.firestore.FieldValue.delete()
        if (!contact.notes) contact.notes = firebase.firestore.FieldValue.delete()
        if (!contact.role) contact.role = firebase.firestore.FieldValue.delete()
        if (!contact.organization) contact.organization = firebase.firestore.FieldValue.delete()
      }
      await contactApi.saveContact(contact)
      this.cancel()
    },
    cancel () {
      this.firstName = ''
      this.lastName = ''
      this.organization = ''
      this.role = ''
      this.phone = ''
      this.address = ''
      this.email = ''
      this.notes = ''
      this.tags = []
      this.open = false
    }
  }
}
</script>

<style scoped>

</style>
