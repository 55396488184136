<template>
  <v-row justify="center">
    <v-dialog
      v-model="open"
      fullscreen
      hide-overlay
      transition="dialog-top-transition">
      <v-card>
        <v-toolbar elevation="2" color="blue" dark>
          <v-toolbar-title>Event</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="saveEvent()">Save</v-btn>
            <v-btn dark text @click="cancel()">Cancel</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-form class="pa-5">
          <v-text-field
            autofocus
            v-model="name"
            label="Event"
            required />
          <v-text-field
            v-model="location"
            label="Where"/>
          <v-row>
            <v-col cols="6">
              <v-dialog
                v-model="showStartDatePicker"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
              <template v-slot:activator="{ on, attrs }">
                <v-container v-bind="attrs" v-on="on" class="d-flex mt-1">
                  <v-icon>mdi-calendar</v-icon>
                  <span class="ml-1">{{startDateDisplay}}</span>
                </v-container>
              </template>
              <v-date-picker
                v-model="startDate"
                @change="showStartDatePicker = false"
              ></v-date-picker>
            </v-dialog>
            </v-col>
            <v-col cols="6">
              <v-switch class="mt-0" v-model="multiDay" label="Multi-Day" color="primary" />
            </v-col>
          </v-row>
          <v-row v-if="multiDay">
            <v-col cols="6">
              <v-dialog
                v-model="showEndDatePicker"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-container v-bind="attrs" v-on="on">
                    <v-icon>mdi-calendar</v-icon>
                    {{endDateDisplay}}
                  </v-container>
                </template>
                <v-date-picker
                  v-model="endDate"
                  @change="showEndDatePicker = false"
                ></v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row v-if="!multiDay">
            <v-col cols="6">
              <v-dialog
                ref="startTimeDialog"
                v-model="pickingTime"
                :return-value.sync="startTime"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-container v-bind="attrs" v-on="on">
                    <v-icon>mdi-clock</v-icon>
                    {{startTimeDisplay}}
                  </v-container>
                </template>
                <v-time-picker
                  v-if="pickingTime"
                  v-model="startTime"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="pickingTime = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.startTimeDialog.save(startTime)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
            <v-col cols="6">
              <v-dialog
                ref="endTimeDialog"
                v-model="pickingEndTime"
                :return-value.sync="endTime"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-container v-bind="attrs" v-on="on">
                    <v-icon>mdi-clock</v-icon>
                    {{endTimeDisplay}}
                  </v-container>
                </template>
                <v-time-picker
                  v-if="pickingEndTime"
                  v-model="endTime"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="pickingEndTime = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.endTimeDialog.save(endTime)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row class="mt-7">
            <tag-selector v-model="tags"></tag-selector>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import eventApi from '@/firebase/eventApi'
import dateTimeUtils from '@/components/services/dateTimeUtils'
import TagSelector from '@/components/inputs/TagSelector'
import tagCoordinator from '@/components/services/TagCoordinator'

export default {
  name: 'EventEditor',
  components: { TagSelector },
  props: {
    trigger: Boolean,
    event: Object
  },
  data: () => ({
    multiDay: false,
    pickingTime: false,
    pickingEndTime: false,
    showStartDatePicker: false,
    showEndDatePicker: false,
    location: null,
    id: null,
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    name: '',
    open: false,
    tags: [],
    selectedTags: tagCoordinator.selectedTags
  }),
  computed: {
    eventStart () {
      if (this.startDate && !this.multiDay) {
        const splitStartDate = this.startDate.split('-')
        let splitTime = [0, 0]
        if (this.startTime) {
          splitTime = this.startTime.split(':')
        }
        return new Date(splitStartDate[0], splitStartDate[1] - 1, splitStartDate[2], splitTime[0], splitTime[1]).getTime()
      } else if (this.startDate && this.multiDay) {
        const splitStartDate = this.startDate.split('-')
        return new Date(splitStartDate[0], splitStartDate[1] - 1, splitStartDate[2]).getTime()
      } else {
        return null
      }
    },
    eventEnd () {
      if (this.startDate && this.endTime && !this.multiDay) {
        const splitStartDate = this.startDate.split('-')
        const splitTime = this.endTime.split(':')
        return new Date(splitStartDate[0], splitStartDate[1] - 1, splitStartDate[2], splitTime[0], splitTime[1]).getTime()
      } else if (this.startDate && this.endDate && this.multiDay) {
        const splitEndDate = this.endDate.split('-')
        return new Date(splitEndDate[0], splitEndDate[1] - 1, splitEndDate[2]).getTime()
      } else {
        return null
      }
    },
    startDateDisplay () {
      if (!this.startDate) return 'Set Start Date'
      else return dateTimeUtils.toDateDisplay(dateTimeUtils.toDateObjectFromDatePicker(this.startDate), 'short')
    },
    endDateDisplay () {
      if (!this.endDate) return 'Set End Date'
      else return dateTimeUtils.toDateDisplay(dateTimeUtils.toDateObjectFromDatePicker(this.endDate), 'short')
    },
    startTimeDisplay () {
      if (this.eventStart) {
        const timeDisplay = dateTimeUtils.toTimeDisplay(this.eventStart)
        return !timeDisplay ? 'Start of Day' : timeDisplay
      } else {
        return 'Set Start Time'
      }
    },
    endTimeDisplay () {
      if (this.eventEnd) {
        return dateTimeUtils.toTimeDisplay(this.eventEnd)
      } else {
        return 'Set End Time'
      }
    }
  },
  methods: {
    async saveEvent () {
      const event = {
        start: this.eventStart,
        end: this.eventEnd,
        name: this.name,
        location: this.location || '',
        multiDay: this.multiDay || false,
        id: this.id,
        tags: this.tags || []
      }
      if (!event.id) delete event.id
      await eventApi.saveEvent(event)
      this.cancel()
    },
    cancel () {
      this.name = ''
      this.id = null
      this.multiDay = false
      this.startDate = null
      this.endDate = null
      this.location = null
      this.startTime = null
      this.endTime = null
      this.tags = []
      this.$emit('update:trigger', false)
    }
  },
  watch: {
    trigger (on) {
      this.open = on
    },
    event: {
      handler (event) {
        if (event.start) {
          const startDate = new Date(event.start || '')
          this.startDate = dateTimeUtils.toDatePickerFormat(startDate)
          this.startTime = dateTimeUtils.toTimePickerFormat(startDate)
        }

        if (event.end) {
          const endDate = new Date(event.end)
          this.endDate = dateTimeUtils.toDatePickerFormat(endDate)
          this.endTime = dateTimeUtils.toTimePickerFormat(endDate)
        }

        this.location = event.location
        this.multiDay = event.multiDay
        this.name = event.name
        this.id = event.id
        this.tags = event.id ? event.tags || [] : this.selectedTags.map(t => t.text)
      }
    }
  }
}
</script>

<style scoped>
  .container {
    padding: 0
  }
</style>
