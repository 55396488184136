import { db } from '@/firebase/firestore'
import authenticator from '@/firebase/authenticator'

const saveEvent = async (event) => {
  const user = await authenticator.getCurrentUser()
  const eventsRef = db.collection(`users/${user.id}/events`)
  if (event.id) {
    await eventsRef.doc(event.id.toString()).update(event)
  } else {
    await eventsRef.add(event)
  }
}
const deleteEvent = async (event) => {
  const user = await authenticator.getCurrentUser()
  const eventsRef = db.collection(`users/${user.id}/events`)
  if (event.id) {
    await eventsRef.doc(event.id.toString()).delete()
  }
}

export default {
  saveEvent,
  deleteEvent
}
