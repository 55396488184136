<template>
  <v-container fluid class="pa-0 ma-0">
    <v-combobox
      v-model="model"
      :filter="filter"
      :hide-no-data="!search"
      :items="items"
      :search-input.sync="search"
      hide-selected
      label="Add Tag"
      multiple
      small-chips
      flat
      solo
      dense
    >
      <template v-slot:no-data>
        <v-list-item @click="addTag()">
          <span class="subheading">Tag with</span>
          <v-chip
            :color="`${nextColor} lighten-3`"
            label
            class="ml-2"
            small
          >
            {{ search }}
          </v-chip>
        </v-list-item>
      </template>
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip
          v-if="item === Object(item)"
          v-bind="attrs"
          :color="`${item.color} lighten-3`"
          :input-value="selected"
          label
          small
        >
          <span class="pr-2">
            {{ item.text }}
          </span>
          <v-icon
            small
            @click="parent.selectItem(item)"
          >
            $delete
          </v-icon>
        </v-chip>
      </template>
      <template v-slot:item="{ index, item }">
        <div v-if="editing === item" class="d-flex align-center">
          <v-text-field
            v-model="editing.text"
            autofocus
            flat
            background-color="transparent"
            hide-details
            solo
            @keyup.enter="edit(index, item)"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog max-width="420" v-model="pickingColor">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar v-on="on" v-bind="attrs" size="20" rounded :color="`${editing.color} lighten-2`"></v-avatar>
            </template>
            <v-card>
              <v-card-text>
                <v-color-picker v-model="editing.color"
                                dot-size="25"
                                hide-canvas
                                hide-inputs
                                hide-sliders
                                mode="rgba"
                                show-swatches
                                swatches-max-height="400"></v-color-picker>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="pickingColor = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <v-chip
          v-else
          :color="`${item.color} lighten-3`"
          dark
          label
          small
        >
          {{ item.text }}
        </v-chip>
        <v-spacer></v-spacer>
        <v-list-item-action @click.stop>
          <v-btn
            icon
            @click.stop.prevent="edit(index, item)"
          >
            <v-icon>{{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
          </v-btn>
        </v-list-item-action>
      </template>
    </v-combobox>
  </v-container>
</template>

<script>
import authenticator from '@/firebase/authenticator'
import { db } from '@/firebase/firestore'
import tagApi from '@/firebase/tagApi'

export default {
  name: 'TagSelector',
  props: ['value'],
  created () {
    authenticator.getCurrentUser().then((user) => {
      this.$bind('items', db.collection(`users/${user.id}/tags`).orderBy('order'))
      this.user = user
    })
  },
  data: () => ({
    activator: null,
    attach: null,
    colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
    currentColor: 'green',
    editing: null,
    editingIndex: -1,
    items: [],
    menu: false,
    model: [],
    x: 0,
    search: null,
    y: 0,
    pickingColor: false,
    newTag: ''
  }),
  computed: {
    nextColor () {
      return this.colors[(this.items.length % 6)]
    }
  },
  watch: {
    items (newVal, oldVal) {
      if (this.newTag) {
        const tagFromSearch = newVal.find(t => t.text === this.newTag)
        if (tagFromSearch) {
          this.model.push(tagFromSearch)
          this.newTag = ''
        }
      }
    },
    value (val, prev) {
      if (val && prev) {
        if (val.length !== prev.length) {
          this.model = []
          val.forEach(v => {
            const itemVal = this.items.find(i => i.text === v)
            if (itemVal) {
              this.model.push(itemVal)
            }
          })
        }
      }
    },
    model (val, prev) {
      if (val.length !== prev.length || val.some(v => typeof v === 'undefined') || val.some(v => typeof v === 'string')) {
        this.model = val
          .filter(v => typeof v !== 'undefined')
          .map((tag, index) => {
            if (typeof tag === 'string') {
              const itemTexts = this.items.map(v => v.text)
              if (!itemTexts.includes(tag)) {
                tag = {
                  text: tag,
                  color: this.nextColor,
                  order: index
                }
                this.newTag = tag.text
                tagApi.saveTag(tag)
              } else {
                tag = this.items.find(i => i.text === tag)
              }
            }
            return tag
          })
      }
      this.search = ''
      this.$emit('input', this.model.map(m => m.text))
    }
  },
  methods: {
    edit (index, item) {
      if (!this.editing) {
        this.editing = item
        this.editingIndex = index
      } else {
        tagApi.saveTag(item)
        this.editing = null
        this.editingIndex = -1
      }
    },
    addTag () {
      this.model.push(this.search)
    },
    filter (item, queryText, itemText) {
      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    }
  }
}
</script>

<style scoped>

</style>
