const selectedTags = []
let mode = 'any'
const selectTag = (tag) => {
  if (!selectedTags.includes(tag)) {
    selectedTags.push(tag)
  }
}
const deselectTag = (tag) => {
  selectedTags.splice(selectedTags.indexOf(tag), 1)
}
const setMode = (newMode) => {
  mode = newMode
}
const itemTagSelected = (taggableItem) => {
  if (selectedTags.length === 0) return true
  else {
    if (!taggableItem) return true
    if (!taggableItem.tags) return false
    if (taggableItem.tags.length === 0) return false
    if (mode === 'any') {
      let foundTag = false
      selectedTags.forEach(t => {
        if (taggableItem.tags.includes(t.text)) {
          foundTag = true
        }
      })
      return foundTag
    } else if (mode === 'all') {
      let allTagsPresent = true
      selectedTags.forEach(t => {
        if (!taggableItem.tags.includes(t.text)) {
          allTagsPresent = false
        }
      })
      return allTagsPresent
    } else {
      return true
    }
  }
}

export default {
  selectTag,
  deselectTag,
  setMode,
  itemTagSelected,
  selectedTags,
  mode
}
