var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-top-transition"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',[_c('v-toolbar',{attrs:{"elevation":"2","color":"blue","dark":""}},[_c('v-toolbar-title',[_vm._v("Event")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.saveEvent()}}},[_vm._v("Save")]),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Cancel")])],1)],1),_c('v-form',{staticClass:"pa-5"},[_c('v-text-field',{attrs:{"autofocus":"","label":"Event","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{attrs:{"label":"Where"},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-dialog',{attrs:{"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-container',_vm._g(_vm._b({staticClass:"d-flex mt-1"},'v-container',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.startDateDisplay))])],1)]}}]),model:{value:(_vm.showStartDatePicker),callback:function ($$v) {_vm.showStartDatePicker=$$v},expression:"showStartDatePicker"}},[_c('v-date-picker',{on:{"change":function($event){_vm.showStartDatePicker = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"label":"Multi-Day","color":"primary"},model:{value:(_vm.multiDay),callback:function ($$v) {_vm.multiDay=$$v},expression:"multiDay"}})],1)],1),(_vm.multiDay)?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-dialog',{attrs:{"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-container',_vm._g(_vm._b({},'v-container',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar")]),_vm._v(" "+_vm._s(_vm.endDateDisplay)+" ")],1)]}}],null,false,4013680754),model:{value:(_vm.showEndDatePicker),callback:function ($$v) {_vm.showEndDatePicker=$$v},expression:"showEndDatePicker"}},[_c('v-date-picker',{on:{"change":function($event){_vm.showEndDatePicker = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1):_vm._e(),(!_vm.multiDay)?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-dialog',{ref:"startTimeDialog",attrs:{"return-value":_vm.startTime,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.startTime=$event},"update:return-value":function($event){_vm.startTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-container',_vm._g(_vm._b({},'v-container',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clock")]),_vm._v(" "+_vm._s(_vm.startTimeDisplay)+" ")],1)]}}],null,false,262859718),model:{value:(_vm.pickingTime),callback:function ($$v) {_vm.pickingTime=$$v},expression:"pickingTime"}},[(_vm.pickingTime)?_c('v-time-picker',{attrs:{"full-width":""},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.pickingTime = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.startTimeDialog.save(_vm.startTime)}}},[_vm._v(" OK ")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-dialog',{ref:"endTimeDialog",attrs:{"return-value":_vm.endTime,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.endTime=$event},"update:return-value":function($event){_vm.endTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-container',_vm._g(_vm._b({},'v-container',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clock")]),_vm._v(" "+_vm._s(_vm.endTimeDisplay)+" ")],1)]}}],null,false,3530260393),model:{value:(_vm.pickingEndTime),callback:function ($$v) {_vm.pickingEndTime=$$v},expression:"pickingEndTime"}},[(_vm.pickingEndTime)?_c('v-time-picker',{attrs:{"full-width":""},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.pickingEndTime = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.endTimeDialog.save(_vm.endTime)}}},[_vm._v(" OK ")])],1):_vm._e()],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-7"},[_c('tag-selector',{model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }