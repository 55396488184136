import { db } from '@/firebase/firestore'
import authenticator from '@/firebase/authenticator'

const tags = []
authenticator.getCurrentUser().then((user) => {
  const tagsRef = db.collection(`users/${user.id}/tags`).orderBy('order')
  tagsRef.onSnapshot((tagsSnapshot) => {
    tagsSnapshot.docChanges().forEach(tagChange => {
      switch (tagChange.type) {
        case 'added':
          tags.push(tagChange.doc.data())
          break
        case 'modified':
          tags.splice(tags.indexOf(tags.find(t => t.id === tagChange.doc.data().id)), 1)
          tags.push(tagChange.doc.data())
          break
        case 'removed':
          tags.splice(tags.indexOf(tags.find(t => t.id === tagChange.doc.data().id)), 1)
          break
      }
    })
  })
})

const saveTag = async (tag) => {
  const user = await authenticator.getCurrentUser()
  const tagsCollection = await db.collection(`users/${user.id}/tags`)
  if (!tag.id) {
    await tagsCollection.add(tag)
  } else {
    await tagsCollection.doc(tag.id).update(tag)
  }
}

const toggleTag = async (tagId, itemId, turnOn) => {
  const user = authenticator.getCurrentUser()
  const tagsCollection = await db.collection(`users/${user.id}/tags`)
  await tagsCollection.doc(`${tagId}/${itemId}`).set(turnOn)
}

export default {
  saveTag,
  toggleTag,
  tags
}
