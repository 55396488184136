<template>
  <v-row justify="center">
    <v-dialog
      v-model="addingTask"
      fullscreen
      hide-overlay
      transition="dialog-top-transition">
      <v-card>
        <v-toolbar elevation="2" color="orange" dark>
          <v-toolbar-title>Task</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="saveTask()">Save</v-btn>
            <v-btn dark text @click="cancel()">Cancel</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-form class="pa-5">
          <!-- title -->
          <v-text-field
            autofocus
            v-model="title"
            label="Title"
            required />
          <!-- effort -->
          <v-slider class="mt-7" :tick-labels="['0:15', '', '', '1:00', '', '', '', '2:00', '', '', '', '3:00', '', '', '', '4:00']" v-model="effort" :thumb-label="addingTask ? 'always' : 'false'" dense ticks color="primary" label="Effort" step="15" min="15" max="240">
            <template v-slot:thumb-label>
              {{effortDisplayText}}
            </template>
          </v-slider>
          <!-- daily -->
          <v-switch class="mt-2" dense v-model="daily" label="Daily" color="primary" />
          <!-- date -->
          <p-date-picker v-if="!daily" v-model="date"></p-date-picker>
          <!-- daily days -->
          <v-btn-toggle rounded color="primary" v-if="daily" class="d-flex mb-5" dense v-model="dailyDays" multiple>
            <v-btn class="flex-grow-1">S</v-btn>
            <v-btn class="flex-grow-1">M</v-btn>
            <v-btn class="flex-grow-1">T</v-btn>
            <v-btn class="flex-grow-1">W</v-btn>
            <v-btn class="flex-grow-1">T</v-btn>
            <v-btn class="flex-grow-1">F</v-btn>
            <v-btn class="flex-grow-1">S</v-btn>
          </v-btn-toggle>
          <!-- timeOfDay -->
          <v-btn-toggle v-if="!daily" class="d-flex flex-row" rounded color="primary" dense v-model="timeOfDay">
            <v-btn class="flex-grow-1" small value="any">Any Time</v-btn>
            <v-btn class="flex-grow-1" small value="morning">Morning</v-btn>
            <v-btn class="flex-grow-1" small value="midday">Mid-Day</v-btn>
            <v-btn class="flex-grow-1" small value="evening">Evening</v-btn>
          </v-btn-toggle>
          <!-- impact -->
          <v-row class="py-5" v-show="!daily">
            <v-col cols="12">
              <v-btn-toggle class="d-flex flex-row" rounded color="primary" dense v-model="impact">
                <v-btn class="flex-grow-1" value="positive"><v-icon color="green">mdi-emoticon-happy</v-icon></v-btn>
                <v-btn class="flex-grow-1" value="neutral"><v-icon color="orange">mdi-emoticon-neutral</v-icon></v-btn>
                <v-btn class="flex-grow-1" value="negative"><v-icon color="red">mdi-emoticon-sad</v-icon></v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <!-- tags -->
          <tag-selector v-model="tags" />
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import taskApi from '@/firebase/taskApi'
import dateTimeUtils from '@/components/services/dateTimeUtils'
import PDatePicker from '@/components/inputs/PDatePicker'
import TagSelector from '@/components/inputs/TagSelector'
import tagCoordinator from '@/components/services/TagCoordinator'

export default {
  name: 'TaskEditor',
  components: { TagSelector, PDatePicker },
  props: {
    trigger: Boolean,
    task: Object
  },
  data: () => ({
    addingTask: false,
    skipTagging: false,
    title: '',
    daily: false,
    id: null,
    effort: 15,
    date: null,
    allDays: 0,
    completedDays: 0,
    lastCalculated: dateTimeUtils.getTimelessToday(),
    order: 999,
    timeOfDay: 'any',
    complete: false,
    impact: 'neutral',
    showDatePicker: false,
    completedOn: null,
    dailyDays: [],
    tags: [],
    selectedTags: tagCoordinator.selectedTags
  }),
  computed: {
    effortDisplayText () {
      return dateTimeUtils.toEffortDisplay(this.effort)
    }
  },
  watch: {
    trigger (val) {
      this.addingTask = val
    },
    task (taskToBind) {
      if (taskToBind) {
        this.title = taskToBind.title
        this.id = taskToBind.id
        this.complete = taskToBind.complete
        this.effort = taskToBind.effort || 15
        this.order = taskToBind.order
        this.tags = taskToBind.id ? taskToBind.tags || [] : this.selectedTags.map(t => t.text)
        this.completedOn = taskToBind.completedOn
        this.daily = taskToBind.daily || false
        this.allDays = taskToBind.allDays || 1
        this.completedDays = taskToBind.completedDays || 0
        this.lastCalculated = taskToBind.lastCalculated || dateTimeUtils.getTimelessToday()
        this.dailyDays = taskToBind.dailyDays || []
        console.log('binding date', taskToBind.date)
        this.date = taskToBind.date
          ? taskToBind.date.toDate ? taskToBind.date.toDate() : new Date(taskToBind.date)
          : null
        this.timeOfDay = taskToBind.timeOfDay || 'any'
        this.impact = taskToBind.impact || 'neutral'
      }
    }
  },
  methods: {
    async saveTask () {
      const task = {
        title: this.title,
        id: this.id,
        complete: this.complete || false,
        daily: this.daily,
        dailyDays: this.dailyDays,
        allDays: this.allDays || 1,
        completedDays: this.completedDays || 0,
        lastCalculated: this.lastCalculated || dateTimeUtils.getTimelessToday(),
        effort: this.effort,
        tags: this.tags || [],
        order: this.order || 999,
        date: this.date ? this.date : undefined,
        timeOfDay: this.timeOfDay,
        impact: this.impact
      }
      if (!task.date) delete task.date
      if (!task.id) delete task.id
      if (!task.completedOn) delete task.completedOn
      await taskApi.saveTask(task, true)
      this.cancel()
      this.$emit('closedTaskEditor')
    },
    cancel () {
      this.$emit('closedTaskEditor')
      this.title = null
      this.daily = false
      this.date = null
      this.effort = 15
      this.order = 999
      this.tags = []
      this.id = null
      this.timeOfDay = 'any'
      this.impact = 'neutral'
      this.completed = false
    }
  }
}
</script>

<style scoped>

</style>
